import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import './NewPlayerForm.css'
import AdminNavigation from '../../admin/components/AdminNavigation'
import { useForm } from '../../shared/hooks/form-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import HomePageCarouselImageUpload from '../../shared/components/FormElements/HomePageCarouselImageUpload'

const HomePageCarouselPhotoForm = () => {
	const { isLoading, error, sendRequest, clearError } = useHttpClient()
	const [file, setFile] = useState()

	let navigate = useNavigate()

	//only need setFormData in Update
	const [formState, inputHandler, setFormData] = useForm()

	//
	//
	//
	const onFileChange = (e) => {
		let pickedFile

		if (e.target.files && e.target.files.length === 1) {
			pickedFile = e.target.files[0]
			console.log('pickedFile: ' + pickedFile)
			setFile(pickedFile)
		}
	}
	//
	//
	//Next, we want to make a call to uploadCarouselPhoto within the player-controller
	const carouselPhotoSubmitHandler = async (event) => {
		console.log('inside carouselPhotoSubmitHandler ' + formState.inputs)
		event.preventDefault()
		try {
			const formData = new FormData()

			formData.append('image', formState.inputs.image.value)
			const responseData = await sendRequest(
				process.env.REACT_APP_BACKEND_URL + '/player/carousel/pic',
				'POST',
				formData
			)
			navigate('/admin/carouselPhoto')
		} catch (err) {
			console.log('upload photo error 2: ' + err)
		}
	}

	if (isLoading) {
		return (
			<div className='center'>
				<LoadingSpinner />
			</div>
		)
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={clearError} />
			{!isLoading && (
				<div>
					<AdminNavigation />
					<main>
						<h2>Add a Photo for the Home Page Photo Carousel</h2>
						<form className='team-form' onSubmit={carouselPhotoSubmitHandler}>
							<HomePageCarouselImageUpload
								center
								id='image'
								//name='image'
								onInput={inputHandler}
							/>
							<div className='addPhotoBtn'>
								<AdminButton type='submit'>Add Selected Photo</AdminButton>
							</div>
						</form>
					</main>
				</div>
			)}
		</React.Fragment>
	)
}

export default HomePageCarouselPhotoForm
