import React, { useState, useEffect } from 'react'
import './Register.css'
import Button from '../../shared/components/UIElements/Button'
import Footer from '../../shared/components/UIElements/Footer'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import Modal from '../../shared/components/UIElements/Modal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
/* import { PAYPAL_CLIENT_ID } from '../../Config/config'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js' */

const Register = () => {
	const [type, setType] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [showSuccessfulPaymentModal, setShowSuccessfulPaymentModal] =
		useState(false)
	const [registeredForWhat, setRegisteredForWhat] = useState()
	const [firstName, setFirstName] = useState()
	const [middleInitial, setMiddleInitial] = useState()
	const [lastName, setLastName] = useState()
	const [phoneNumber, setPhoneNumber] = useState()
	const [address, setAddress] = useState()
	const [city, setCity] = useState()
	const [state, setState] = useState()
	const [zip, setZip] = useState()
	const [email, setEmail] = useState()
	const [email2, setEmail2] = useState()
	const [dateOfBirth, setDateOfBirth] = useState()
	const [parentName, setParentName] = useState()
	const [dropdownArray, setDropdownArray] = useState()
	const [loadedRegistrationLeagues, setLoadedRegistrationLeagues] = useState()
	const [price, setPrice] = useState()

	//First, lets tap into the following route on the backend to get all
	//the different leagues that are available for registration
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL +
						`/registration/registrationLeagues`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedRegistrationLeagues(responseData.allRegistrationLeagues)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [])

	let registrationType

	const getRegistrationType = (event) => {
		registrationType = event.target.value
		setType(event.target.value)
		console.log('registrationType gotten: ' + registrationType)
		//
		//
		//Then here is where we CALL the secondDropdownFunction and feed it the registrationType
		secondDropdownFunction(registrationType)
	}
	const getRegisteredForWhat = (event) => {
		setRegisteredForWhat(event.target.value)
		console.log('registeredForWhat: ' + event.target.value)
		//
		//Now that we've determined exactly what they're signing up for, we need to display the price
		displayPrice(event.target.value)
	}
	const getFirstName = (event) => {
		setFirstName(event.target.value)
	}
	const getMiddleInitial = (event) => {
		setMiddleInitial(event.target.value)
	}
	const getLastName = (event) => {
		setLastName(event.target.value)
	}
	const getPhoneNumber = (event) => {
		setPhoneNumber(event.target.value)
	}
	const getAddress = (event) => {
		setAddress(event.target.value)
	}
	const getCity = (event) => {
		setCity(event.target.value)
	}
	const getState = (event) => {
		setState(event.target.value)
	}
	const getZip = (event) => {
		setZip(event.target.value)
	}
	const getEmail = (event) => {
		setEmail(event.target.value)
	}
	const getEmail2 = (event) => {
		setEmail2(event.target.value)
	}
	const getDOB = (event) => {
		setDateOfBirth(event.target.value)
	}
	const getParentName = (event) => {
		setParentName(event.target.value)
	}
	//
	//
	let navigate = useNavigate()
	//
	//
	let typesArray, uniqueTypeArray, duplicateFilter, secondDropdownArray
	typesArray = []
	uniqueTypeArray = []
	secondDropdownArray = []

	//
	//
	//
	//
	if (loadedRegistrationLeagues && !isLoading) {
		loadedRegistrationLeagues.forEach((league) => {
			typesArray.push(league.type)
		})
		duplicateFilter = typesArray.filter((type) => {
			if (!uniqueTypeArray.includes(type)) {
				uniqueTypeArray.push(type)

				return true
			}
			return false
		})
	}
	//
	//
	//
	//
	//
	//
	const secondDropdownFunction = async (registrationType) => {
		setIsLoading(true)
		loadedRegistrationLeagues.forEach((league) => {
			if (league.type === registrationType) {
				secondDropdownArray.push(league.leagueNameAndDesc)
			}
		})
		setDropdownArray(secondDropdownArray)
		setIsLoading(false)
	}
	//
	//
	//
	//
	//let price
	const displayPrice = async (registeredForWhat) => {
		loadedRegistrationLeagues.forEach((type) => {
			if (type.leagueNameAndDesc === registeredForWhat) {
				setPrice(type.leaguePrice)
			}
		})
	}
	//
	//
	//
	//
	const registerSubmitHandler = async (event) => {
		event.preventDefault()
		console.log(
			'Finally, you are now inside registerSubmitHandler...' +
				' ' +
				registeredForWhat +
				' ' +
				firstName
		)

		try {
			setIsLoading(true)
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + '/registration/playerRegistration',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						type,
						registeredForWhat,
						firstName,
						middleInitial,
						lastName,
						phoneNumber,
						address,
						city,
						state,
						zip,
						email,
						email2,
						dateOfBirth,
						parentName,
					}),
				}
			)
			const responseData = await response.json()
			if (!response.ok) {
				throw new Error('ERROR: ' + responseData.message)
			}

			console.log('you are here, lets show the modal')
			showSuccessfulPaymentHandler()
			//setIsLoading(false)
		} catch (err) {
			console.log('error: ' + err)
			setIsLoading(false)
			setError(
				err.message + ': Something went wrong with creating a registration.'
			)
		}
	}

	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//When user clicks OKAY on the popup modal (popup that says their payment was successful)
	//all this does is A: close the popup, and B: go back to the home page
	const okHandler = () => {
		console.log('ok has been clicked')
		setShowSuccessfulPaymentModal(null)
		navigate('/')
	}
	//
	//
	//
	const showSuccessfulPaymentHandler = () => {
		console.log('showSuccessfulPaymentModal? ' + showSuccessfulPaymentModal)
		setShowSuccessfulPaymentModal(true)
	}
	//
	//

	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<Modal
					show={showSuccessfulPaymentModal}
					header='Thank You!'
					footerClass='place-item__modal-actions'
					footer={
						<React.Fragment>
							<p className='modalText'>
								Thanks for registering. Let's get ready to PLAY!!
							</p>
							<br></br>
							<button
								className='okButton buttonButton'
								inverse
								onClick={okHandler}
							>
								OKAY
							</button>
						</React.Fragment>
					}
				></Modal>
				<ErrorModal error={error} onClear={errorHandler} />
				<div>
					{isLoading && <LoadingSpinner asOverlay />}
					<main>
						<h2>Welcome to the Sloths!</h2>
						<h5>Register here to get on the email list</h5>
						<div className='rcard'>
							<div className='rcard-body rcard-padding'>
								<form
									className='register-form'
									onSubmit={registerSubmitHandler}
								>
									{!isLoading && loadedRegistrationLeagues && (
										<div>
											<div className='row' style={{ paddingBottom: '2rem' }}>
												<div className='form-group fg-line net'>
													<label
														htmlFor='txtTeamName'
														style={{ fontWeight: 'bold' }}
													>
														What are you registering for?
														<span className='c-red'>*</span>
													</label>
													<select
														name='ctl00$cphBody$drpMyTeam'
														id='ctl00_cphBody_drpMyTeam'
														onChange={getRegistrationType}
														tabIndex='2'
														className='textbox'
														required
													>
														<option defaultValue></option>
														{duplicateFilter.map((type, i) => (
															<option value={type} key={i}>
																{type.toUpperCase()}
															</option>
														))}
													</select>
												</div>
											</div>
											{dropdownArray && (
												<div className='row' style={{ paddingBottom: '2rem' }}>
													<div className='form-group fg-line net'>
														<label
															htmlFor='txtTeamName'
															style={{ fontWeight: 'bold' }}
														>
															Division Name<span className='c-red'>*</span>
														</label>
														<select
															name='ctl00$cphBody$drpMyTeam'
															id='ctl00_cphBody_drpMyTeam'
															onChange={getRegisteredForWhat}
															tabIndex='2'
															className='textbox'
															required
														>
															<option defaultValue></option>
															{dropdownArray.map((league, i) => (
																<option value={league} key={i}>
																	{league}
																</option>
															))}
														</select>
														{price && (
															<label
																htmlFor='txtPrice'
																style={{
																	marginLeft: '4rem',
																	fontWeight: 'bold',
																}}
															>
																Price: ${price}
															</label>
														)}
													</div>
												</div>
											)}
										</div>
									)}
									<div className='row'>
										<div className='row'>
											<div className='col-sm-4'>
												<div
													className='form-group fg-line net'
													style={{ marginBottom: '2rem' }}
												>
													<label
														htmlFor='txtFirstName'
														style={{ fontWeight: 'bold' }}
													>
														Player First Name: <span className='c-red'>*</span>
													</label>
													<input
														name='ctl00$cphBody$txtFirstName'
														type='text'
														id='txtFirstName'
														tabIndex='6'
														className='rform-control'
														placeholder='First Name'
														onChange={getFirstName}
														required
													/>
												</div>
											</div>
											<div className='col-sm-4'>
												<div
													className='form-group fg-line net'
													style={{ marginBottom: '2rem' }}
												>
													<label
														htmlFor='txtMiddleInitial'
														style={{ fontWeight: 'bold' }}
													>
														Middle Initial:
													</label>
													<input
														name='ctl00$cphBody$txtMiddleInitial'
														type='text'
														maxLength={1}
														id='txtMiddleInitial'
														tabIndex='6'
														className='rform-control'
														placeholder='Middle Initial'
														onChange={getMiddleInitial}
													/>
												</div>
											</div>
											<div className='col-sm-4'>
												<div
													className='form-group fg-line net'
													style={{ marginBottom: '2rem' }}
												>
													<label
														htmlFor='txtLastName'
														style={{ fontWeight: 'bold' }}
													>
														Player Last Name: <span className='c-red'>*</span>
													</label>
													<input
														name='ctl00$cphBody$txtLastName'
														type='text'
														id='txtLastName'
														tabIndex='7'
														className='rform-control'
														onChange={getLastName}
														placeholder='Last Name'
														required
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-8 col-lg-6'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label
													htmlFor='txtHomePhone'
													style={{ fontWeight: 'bold' }}
												>
													Phone Number: <span className='c-red'>*</span>
												</label>
												<input
													name='ctl00$cphBody$txtHomePhone'
													type='text'
													id='txtHomePhone'
													tabIndex='8'
													className='rform-control'
													placeholder='Home Phone'
													onChange={getPhoneNumber}
													required
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-12 col-lg-6'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='address' style={{ fontWeight: 'bold' }}>
													Address 1: {/* <span className='c-red'>*</span> */}
												</label>
												<input
													id='address'
													type='text'
													tabIndex='9'
													placeholder='Address'
													className='rform-control'
													onChange={getAddress}
													/* required */
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-lg-6 col-lg-3'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='txtCity' style={{ fontWeight: 'bold' }}>
													City: {/* <span className='c-red'>*</span> */}
												</label>
												<input
													name='ctl00$cphBody$txtCity'
													type='text'
													id='txtCity'
													tabIndex='10'
													className='rform-control'
													placeholder='City'
													onChange={getCity}
													// required
												/>
											</div>
										</div>
										<div className='col-sm-3'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label
													htmlFor='txtState'
													style={{ fontWeight: 'bold' }}
												>
													State: {/* <span className='c-red'>*</span> */}
												</label>
												<input
													name='ctl00$cphBody$txtState'
													type='text'
													id='txtState'
													tabIndex='11'
													className='rform-control'
													placeholder='State'
													onChange={getState}
													// required
												/>
											</div>
										</div>
										<div className='col-lg-3 col-sm-8'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='txtZip' style={{ fontWeight: 'bold' }}>
													Zip: {/* <span className='c-red'>*</span> */}
												</label>
												<input
													name='ctl00$cphBody$txtState'
													type='text'
													id='txtZip'
													tabIndex='12'
													className='rform-control'
													placeholder='12345'
													pattern='[0-9]{5}'
													onChange={getZip}
													// required
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-3'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='email' style={{ fontWeight: 'bold' }}>
													Email Address: <span className='c-red'>*</span>
												</label>
												<input
													id='email'
													tabIndex='15'
													type='email'
													className='rform-control'
													onChange={getEmail}
													placeholder='Email Address'
													required
												/>
											</div>
										</div>
										<div className='col-sm-3'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='email' style={{ fontWeight: 'bold' }}>
													Second Email Address (optional):
												</label>
												<input
													id='email2'
													tabIndex='16'
													type='email'
													className='rform-control'
													onChange={getEmail2}
													placeholder='Email Address'
												/>
											</div>
										</div>
										<div className='col-sm-3'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='dob' style={{ fontWeight: 'bold' }}>
													Date of Birth:{' '}
													{/* <span className='c-red'>*</span> */}
												</label>
												<input
													type='date'
													id='dob'
													name='dob'
													tabIndex='16'
													onChange={getDOB}
													// required
												/>
											</div>
										</div>
									</div>
									<div>
										<div className='row'>
											<div className='col-sm-3'>
												<div
													className='form-group fg-line net'
													style={{ marginBottom: '2rem' }}
												>
													<label
														htmlFor='parent'
														style={{ fontWeight: 'bold' }}
													>
														Parents Name:
													</label>
													<input
														id='parent'
														type='text'
														tabIndex='18'
														placeholder='Parents Name'
														className='rform-control'
														onChange={getParentName}
													/>
												</div>
											</div>
										</div>
									</div>
									<Button type='submit'>REGISTER</Button>
								</form>
							</div>
						</div>
					</main>
				</div>
				<Footer />
			</React.Fragment>
		</div>
	)
}

export default Register
