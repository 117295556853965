//I've decided to do this page NOT by venue, but just a long list
import React, { useState, useEffect } from 'react'
import './FullSchedule.css'
import { useParams, Link } from 'react-router-dom'
import Footer from '../components/UIElements/Footer'
import LoadingSpinner from '../components/UIElements/LoadingSpinner'
import SchedulesHomeNavigation from '../components/Navigation/SchedulesHomeNavigation'
//import SearchByLeagueOrVenue from '../components/UIElements/SearchByLeagueOrVenue'

const VenueSchedule = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [allVenueGamesAndEvents, setAllVenueGamesAndEvents] = useState([])

	const venueId = useParams().venueId

	console.log('INSIDE VENUE SCHEDULE')

	//Get all games scheduled at this venue.  league-controller  getVenueSchedule
	useEffect(() => {
		//
		//console.log('INSIDE USE EFFECT')
		const sendRequest = async () => {
			setIsLoading(true)
			setAllVenueGamesAndEvents([])
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/league/${venueId}/schedule`
				)

				const responseData = await response.json()

				//console.log('venue games: ' + JSON.stringify(responseData))

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				//
				//I changed the responseData below on 7/6/2023 to get the gamesUpload to work
				//for the NBHL.  It shows ALL games that ever took place there, so eventually,
				//if I get NBHL, I'll need to fix that
				//
				setAllVenueGamesAndEvents(
					//responseData.allVenueGamesAndEventsArrayThreeWeeks
					responseData.allVenueGamesAndEventsArray
				)

				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [venueId])
	//
	//
	//console.log('allLeagueGames: ' + JSON.stringify(allLeagueGames))
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	if (isLoading) {
		return (
			<div className='center' style={{ marginTop: '10rem' }}>
				<LoadingSpinner />
			</div>
		)
	}
	//
	//
	//
	let dateBlock
	dateBlock = []
	if (!isLoading && allVenueGamesAndEvents.length === 0) {
		return (
			<div style={{ overflowX: 'hidden' }}>
				<React.Fragment>
					<SchedulesHomeNavigation />
					<div className='Leagues'>
						<h1>NOTHING SCHEDULED YET!</h1>
					</div>
				</React.Fragment>
			</div>
		)
	}
	if (!isLoading && allVenueGamesAndEvents.length !== 0) {
		// distinctVenuesArray.forEach((venue) => {
		//allLeagueGames.forEach((game) => {
		dateBlock.push(
			<div className='Schedule'>
				{/* <AdminNavigation /> */}
				{/* <h1>Schedule</h1> */}
				<h1>{allVenueGamesAndEvents[0].venueName}</h1>
				<p>(Click on the final score of a game to see a Game Summary)</p>
				<table id='schedulePlayers'>
					<thead>
						<tr>
							<th>Date</th>
							<th>Time</th>
							<th>Game or Event</th>
							<th>Status</th>
							<th>Score</th>
							<th>Opponent</th>
							<th></th>
							<th>Winner</th>
							{/* <th>Venue</th> */}
						</tr>
					</thead>
					<tbody>
						{allVenueGamesAndEvents.map((game, key) => {
							// if (game.venueName === venue) {
							return (
								<tr key={key}>
									<td>
										{game.dayOfWeek}
										{', '}
										{game.date.substr(0, 2) === '01' &&
											'Jan. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '02' &&
											'Feb. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '03' &&
											'Mar. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '04' &&
											'Apr. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '05' &&
											'May ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '06' &&
											'June ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '07' &&
											'July ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '08' &&
											'Aug. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '09' &&
											'Sept. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '10' &&
											'Oct. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '11' &&
											'Nov. ' + game.date.substr(3, 2)}
										{game.date.substr(0, 2) === '12' &&
											'Dec. ' + game.date.substr(3, 2)}
									</td>
									<td>
										{game.time.substr(0, 2) === '00' &&
											'12:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '01' &&
											'1:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '02' &&
											'2:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '03' &&
											'3:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '04' &&
											'4:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '05' &&
											'5:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '06' &&
											'6:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '07' &&
											'7:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '08' &&
											'8:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '09' &&
											'9:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '10' &&
											'10:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '11' &&
											'11:' + game.time.substr(3, 2) + ' am'}
										{game.time.substr(0, 2) === '12' &&
											'12:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '13' &&
											'1:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '14' &&
											'2:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '15' &&
											'3:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '16' &&
											'4:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '17' &&
											'5:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '18' &&
											'6:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '19' &&
											'7:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '20' &&
											'8:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '21' &&
											'9:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '22' &&
											'10:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '23' &&
											'11:' + game.time.substr(3, 2) + ' pm'}
										{game.time.substr(0, 2) === '24' &&
											'12:' + game.time.substr(3, 2) + ' am'}
									</td>
									<td>
										{(game.leagueName && game.leagueName) || game.eventName}
									</td>
									<td>
										{(game.status && game.status !== 'TBP' && (
											<Link
												style={{
													textDecoration: 'none',
													fontWeight: 600,
													color: 'black',
												}}
												to={`/gameSummary/${game._id}`}
											>
												{game.status}
											</Link>
										)) ||
											'TBP'}
									</td>
									<td>
										{game.score && (
											<Link
												style={{
													textDecoration: 'none',
													fontWeight: 600,
													color: 'black',
												}}
												to={`/gameSummary/${game._id}`}
											>
												{game.score}
											</Link>
										)}
									</td>
									{game.visitorTeamName && (
										<td>
											{game.visitorTeamSeed > 0 &&
												'(' + game.visitorTeamSeed + ') '}
											{game.visitorTeamName && game.visitorTeamName}
											{' @ '}
											{game.homeTeamSeed > 0 && '(' + game.homeTeamSeed + ') '}
											{game.homeTeamName && game.homeTeamName}
										</td>
									)}
									{game.eventName && (
										<td
											style={{
												fontWeight: 'bold',
												backgroundColor: 'pink',
											}}
										>
											{game.eventName}
										</td>
									)}
									{!game.playoff && !game.championship && <td></td>}
									{game.playoff && (
										<td style={{ color: 'red', fontWeight: 'bold' }}>
											PLAYOFF GAME
										</td>
									)}

									{game.championship && (
										<td style={{ color: 'red', fontWeight: 'bold' }}>
											CHAMPIONSHIP GAME
										</td>
									)}
									{game.winner && game.winner === game.homeTeamId && (
										<td>{game.homeTeamName}</td>
									)}
									{game.winner && game.winner === game.visitorTeamId && (
										<td>{game.visitorTeamName}</td>
									)}
									{game.status &&
										game.status !== 'TBP' &&
										game.winner !== game.homeTeamId &&
										game.winner !== game.visitorTeamId && <td>TIE</td>}
									{!game.score && <td></td>}
								</tr>
							)
							//}
						})}
					</tbody>
				</table>
			</div>
		)
		// }
		//)
	}

	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<SchedulesHomeNavigation />
				{/* <SearchByLeagueOrVenue /> */}
				{dateBlock && dateBlock}
				<Footer />
			</React.Fragment>
		</div>
	)
}

export default VenueSchedule
