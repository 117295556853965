//This is for listing all players in the entire system

import React, { useEffect, useState } from 'react'
import './AdminLeagueList.css'
import AdminNavigation from './AdminNavigation'
import { NavLink } from 'react-router-dom'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'

const AdminAllPlayersInSystemList = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [loadedAllPlayers, setLoadedAllPlayers] = useState()

	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/getAllPlayers'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedAllPlayers(responseData.allPlayers)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [])

	const errorHandler = () => {
		setError(null)
	}

	//Once we have all loaded players, let's sort them by lastName/firstName
	if (loadedAllPlayers) {
		loadedAllPlayers.sort(function (a, b) {
			return (
				a.lastName.localeCompare(b.lastName) ||
				a.firstName.localeCompare(b.firstName)
			)
		})
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && loadedAllPlayers && (
				<div className='AdminLeagues'>
					<AdminNavigation />
					<h1>All Players in the system:</h1>
					<div className='row'>
						<NavLink
							to={`/admin/newPlayer`}
							exact
							className='centeredButton'
							style={{ display: 'block', width: 'auto', fontWeight: '700' }}
						>
							Click here to add new players.
						</NavLink>
					</div>
					<table id='leagues'>
						<thead>
							<tr>
								<th>Last Name</th>
								<th>First Name</th>
								<th>Middle Initial</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{loadedAllPlayers.map((player, key) => {
								//const array = player.split(' ')
								let lastName = player.lastName
								let firstName = player.firstName
								let middleInitial = player.middleInitial
								if (!middleInitial) {
									middleInitial = ' '
								}
								return (
									<tr key={key}>
										<td>{lastName}</td>
										<td>{firstName}</td>
										<td>{middleInitial}</td>
										<td>
											<NavLink
												className='navlinks'
												to={`/admin/updatePlayer/${player._id}`}
												exact
											>
												Edit
											</NavLink>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminAllPlayersInSystemList
