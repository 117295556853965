//Create a roster for each team.
//Even while not logged in, you can see a list of players within the chosen team.
//This here is a page that OUTPUTS that list.
import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import './Players.css'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Footer from '../../shared/components/UIElements/Footer'
import StatsAndStandingsHome from '../../shared/components/Navigation/StatsAndStandingsHome'
import sloths from '../../shared/components/images/Sloths_Logo_Circle.png'

const RosterAndSchedulePage = () => {
	console.log('inside RosterAndSchedulePage')
	const [error, setError] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [rosteredPlayers, setRosteredPlayers] = useState()
	const [assignedPlayers, setAssignedPlayers] = useState()
	const [gamesList, setGamesList] = useState()
	const [wins, setWins] = useState()
	const [losses, setLosses] = useState()
	const [overtimeLosses, setOvertimeLosses] = useState()
	const [shootoutLosses, setShootoutLosses] = useState()
	const [ties, setTies] = useState()
	const [teamName, setTeamName] = useState()
	const [year, setYear] = useState()

	//taps into LEAGUE backend, getPlayersOnTeam.
	//Also returns wins, losses, OTlosses, and SOlosses
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					// `${process.env.REACT_APP_BACKEND_URL}/league/${leagueId}/${session}/${year}/${teamName}`
					`${process.env.REACT_APP_BACKEND_URL}/league/rosterAndSchedule`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}
				setRosteredPlayers(responseData.rosteredPlayers)
				setAssignedPlayers(responseData.assignedPlayers)
				setWins(responseData.wins)
				setLosses(responseData.losses)
				setOvertimeLosses(responseData.overtimeLosses)
				setShootoutLosses(responseData.shootoutLosses)
				setGamesList(responseData.allGamesAndEventsArray)
				setTies(responseData.ties)
				setTeamName(responseData.teamName)
				setYear(responseData.year)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	let rem
	if (assignedPlayers) {
		console.log('assignedPlayers: ' + assignedPlayers)
		if (assignedPlayers > 10) {
			rem = '2rem'
		} else {
			rem = '10rem'
		}
	}
	//
	//
	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<StatsAndStandingsHome />
				<ErrorModal error={error} onClear={errorHandler} />
				{isLoading && (
					<div className='center' style={{ marginTop: '10rem' }}>
						<LoadingSpinner />
					</div>
				)}
				{!isLoading && rosteredPlayers && gamesList && (
					<React.Fragment>
						<div className='Roster' style={{ marginTop: '6rem' }}>
							<div>
								<img src={sloths} className='sloths' />
							</div>
							<h2>{year}</h2>
							<h3 className='h3Center'>
								Record: {wins} - {losses} -{' '}
								{ties + overtimeLosses + shootoutLosses}
							</h3>
							<table id='roster'>
								<thead>
									<tr>
										<th>Number</th>
										<th>Name</th>
										<th>G</th>
										<th>A</th>
										<th>Total Points</th>
									</tr>
								</thead>
								<tbody>
									{rosteredPlayers.map((player, key) => {
										{
											return (
												<tr key={key}>
													<td>{player.number}</td>
													<td className='tableDataTeamName'>
														<Link
															style={{
																textDecoration: 'none',
																color: 'black',
																fontWeight: 600,
															}}
															to={`/${player.playerId}/history`}
														>
															{player.firstName} {player.lastName}
														</Link>
													</td>
													<td>{player.goals}</td>
													<td>{player.assists}</td>
													<td>{player.goals + player.assists}</td>
												</tr>
											)
										}
									})}
								</tbody>
							</table>
						</div>
						<div style={{ marginTop: `${rem}` }}>
							<Footer />
						</div>
					</React.Fragment>
				)}
			</React.Fragment>
		</div>
	)
}

export default RosterAndSchedulePage
