import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import './RegisterNavigation.css'
import HomeHeader from '../../shared/components/Navigation/HomeHeader'
import HomeNavLinks from '../../shared/components/Navigation/HomeNavLinks'
import SideDrawer from '../../admin/components/SideDrawer'
import Backdrop from '../../shared/components/UIElements/Backdrop'

const RegisterHomeNavigation = () => {
	const [drawerIsOpen, setDrawerIsOpen] = useState(false)

	/* console.log(
		'SchedulesHomeNavigation props: ' + JSON.stringify(props.leagueId)
	) */

	const openDrawerHandler = () => {
		setDrawerIsOpen(true)
	}

	const closeDrawerHandler = () => {
		setDrawerIsOpen(false)
	}

	return (
		<React.Fragment>
			{drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
			<SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
				<nav className='register-navigation__drawer-nav'>
					<HomeNavLinks />
				</nav>
			</SideDrawer>
			<HomeHeader>
				<button
					className='register-navigation__menu-btn'
					onClick={openDrawerHandler}
				>
					<span />
					<span />
					<span />
				</button>
				<h1 className='home-navigation__title'>Player Registration</h1>
				<nav className='register-navigation__header-nav'>
					<HomeNavLinks />
				</nav>
			</HomeHeader>
		</React.Fragment>
	)
}

export default RegisterHomeNavigation
