import React, { useEffect, useState } from 'react'
import './AdminTeamList.css'
import { NavLink } from 'react-router-dom'
import Modal from '../../shared/components/UIElements/Modal'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import AdminNavigation from './AdminNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminPhotoList = () => {
	const { sendRequest, clearError } = useHttpClient()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [loadedCarouselPhotos, setLoadedCarouselPhotos] = useState()
	const [showConfirmModal, setShowConfirmModal] = useState(false)
	const [deletedPhotoId, setDeletedPhotoId] = useState('')
	const [photoDeleted, setPhotoDeleted] = useState()
	//
	//Get all Carousel photos
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			setPhotoDeleted(false)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/carouselPhotos'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedCarouselPhotos(responseData.allCarouselPhotos)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			//
			setIsLoading(false)
		}
		sendRequest()
	}, [photoDeleted])
	//
	//

	const errorHandler = () => {
		setError(null)
	}

	//******************************************************** */
	//
	//     Delete warning
	//
	//******************************************************** */
	const showDeleteWarningHandler = (e) => {
		const value = e.target.id
		setDeletedPhotoId(loadedCarouselPhotos[value]._id)
		setShowConfirmModal(true)
	}

	const cancelDeleteHandler = () => {
		setShowConfirmModal(false)
	}

	//console.log('league right here: ' + leagueId)
	const confirmDeleteHandler = async () => {
		setShowConfirmModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${deletedPhotoId}/removeCarouselPhoto`,
				'DELETE'
			)
		} catch (err) {}
		setPhotoDeleted(true)
	}

	return (
		<React.Fragment>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			<Modal
				show={showConfirmModal}
				onCancel={cancelDeleteHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to delete this photo?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelDeleteHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							style={{ color: 'red' }}
							danger
							onClick={confirmDeleteHandler}
						>
							DELETE PHOTO
						</button>
					</React.Fragment>
				}
			></Modal>
			{!isLoading && loadedCarouselPhotos && (
				<div className='Teams'>
					<AdminNavigation />
					<h1>Carousel Photos</h1>
					<h5>
						Use this page to add photos for the picture carousel on the home
						page.
					</h5>
					<div
						style={{
							marginBottom: '2rem',
							display: 'table',
							marginRight: 'auto',
							marginLeft: 'auto',
						}}
					>
						<AdminButton
							to={`/admin/carouselPhoto`}
							exact
							style={{
								color: 'white',
								textDecoration: 'none',
							}}
						>
							Add a new Photo
						</AdminButton>
					</div>
					<table id='carousel'>
						<thead>
							<tr>
								<th>Photo Name</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{loadedCarouselPhotos.map((photo, key) => {
								return (
									<tr key={key}>
										<td>{photo.photo}</td>
										<td>
											<img
												style={{ margin: 'auto' }}
												className='d-block w-20'
												src={`${process.env.REACT_APP_AWS_BUCKET}/${photo.photo}`}
												alt='Image One'
											/>
										</td>
										<td style={{ paddingRight: '1rem' }}>
											<button
												className='buttonButton'
												style={{ color: 'red' }}
												id={key}
												onClick={(e) => showDeleteWarningHandler(e)}
											>
												DELETE
											</button>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
					<div
						style={{
							marginTop: '3rem',
							marginBottom: '2rem',
							display: 'table',
							marginRight: 'auto',
							marginLeft: 'auto',
						}}
					>
						<AdminButton
							to={`/admin/carouselPhoto`}
							exact
							style={{
								color: 'white',
								textDecoration: 'none',
							}}
						>
							Add a new Photo
						</AdminButton>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminPhotoList
