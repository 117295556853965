import React, { useState, useEffect } from 'react'
import './Home.css'
import Footer from '../UIElements/Footer'
import HomeHomeNavigation from './HomeHomeNavigation'
import LoadingSpinner from '../UIElements/LoadingSpinner'
import ErrorModal from '../UIElements/ErrorModal'
import YouTube from 'react-youtube'
import Carousel from 'react-bootstrap/Carousel'
var getYouTubeID = require('get-youtube-id')

const Home = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [ids, setIds] = useState([])
	const [videoTitles, setVideoTitles] = useState([])
	const [videoCaptions, setVideoCaptions] = useState([])
	const [loadedVideos, setLoadedVideos] = useState([])
	const [loadedNews, setLoadedNews] = useState([])
	const [loadedCarouselPhotos, setLoadedCarouselPhotos] = useState([])
	//console.log('auth: ' + JSON.stringify(auth))

	//Get all videos designated for the Home Page
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/homePageVideos'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedVideos(responseData.allHomePageVideos)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			//
			setIsLoading(false)
		}

		sendRequest()
	}, [])

	let arrayOfTitles, arrayOfIds, arrayOfCaptions
	arrayOfTitles = []
	arrayOfIds = []
	arrayOfCaptions = []
	if (loadedVideos && !isLoading && ids.length === 0) {
		loadedVideos.forEach((video) => {
			arrayOfTitles.push(video.videoTitle)
			arrayOfIds.push(getYouTubeID(video.videoURL))
			arrayOfCaptions.push(video.videoCaption)
		})
		console.log(arrayOfTitles.length)
	}

	if (arrayOfTitles.length > 0) {
		setVideoTitles(arrayOfTitles)
	}
	if (arrayOfIds.length > 0) {
		setIds(arrayOfIds)
	}
	if (arrayOfCaptions.length > 0) {
		setVideoCaptions(arrayOfCaptions)
	}

	if (videoCaptions.length > 0) {
		videoCaptions.forEach((caption) => {
			console.log(caption)
		})
	}
	//
	//
	//Get all Carousel photos
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/carouselPhotos'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedCarouselPhotos(responseData.allCarouselPhotos)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			//
			setIsLoading(false)
		}
		sendRequest()
	}, [])
	//
	//
	loadedCarouselPhotos &&
		console.log('loadedCarouselPhotos: ' + JSON.stringify(loadedCarouselPhotos))
	//
	//
	//
	//
	//
	//
	//Get all News
	useEffect(() => {
		const sendRequest2 = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + '/admin/news'
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				console.log(responseData.allNews)

				setLoadedNews(responseData.allNews)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			//
			//
			setIsLoading(false)
		}

		sendRequest2()
	}, [])
	//
	//
	//
	//
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<ErrorModal error={error} onClear={errorHandler} />
				{isLoading && <LoadingSpinner asOverlay />}
				{!isLoading && (
					<div>
						<header className='homeHeader'>
							<HomeHomeNavigation />
							{/* <!-- banner --> */}
							{/* <div className='text-light text-md-right text-center banner headingWriting'>
								<h1 className=' mb-4 text-white headingText'>
									South Jersey Sloths
								</h1>
								<div className='underline-white mb-4'></div>
								<h1
									className='text-white subheading'
									style={{ marginBottom: '1rem' }}
								>
									Teamwork Makes the Dream Work.
								</h1>
							</div> */}
							<h1 className=' mb-4 text-white headingText'>
								South Jersey Sloths
							</h1>
							<div className='whiteLine underline-white mb-4'></div>
						</header>
						{/* <h1 className='text-white teamWork'>
							Teamwork Makes the Dream Work.
						</h1> */}
						<div className='section pt-5'>
							<div className=''>
								{/* <!-- title --> */}
								<div className='row'>
									<div className='col text-center'>
										{' '}
										{/* mb-3 */}
										{/* <h1 className='text-sh-dark-blue display-2'>
											News and Updates
										</h1> */}
										<h1 className='text-white teamWork'>
											Teamwork Makes the Dream Work.
										</h1>
										<p className='lead text-secondary'></p>
									</div>
								</div>
								{/* <!-- end of title --> */}
								<div className='row'>
									<div className='col-lg-3 col-sm-6'></div>
									{/* <div className='col-lg-6 col-sm-6'>
									<p style={{ textAlign: 'center' }}>put some news here...</p>
								</div> */}
									{!isLoading &&
										loadedNews &&
										loadedNews.map((news, key) => {
											return (
												<div>
													<h1 style={{ color: 'red' }}>{news.newsHeading}</h1>

													<p
														className='caption'
														style={{
															textAlign: 'center',
															color: 'black',
														}}
													>
														{news.newsSubheading}
													</p>
													<p
														className='caption'
														style={{
															textAlign: 'center',
															color: 'black',
														}}
													>
														{news.newsDate}
													</p>
													<h3
														className='content'
														style={{
															textAlign: 'center',
															color: 'black',
															marginBottom: '3rem',
															whiteSpace: 'pre-wrap',
														}}
													>
														{news.newsContent}
													</h3>
													{/* {
													<YouTube
														style={{ paddingBottom: '3rem' }}
														videoId={ids[key]}
													/>
												} */}
												</div>
											)
										})}
									<div className='col-lg-3 col-sm-6'></div>
								</div>
							</div>
							{/* <div className='underline mb-5 mt-5'></div> */}
						</div>
						{/* //
						//
						//
						//     CAROUSEL
						//
						//
						//
						// */}
						<div className='section pt-5'>
							<div className=''>
								<div className='row'>
									<div className='col text-center mb-3'>
										<h1 className='text-white teamWork'>Check it out!</h1>
									</div>
								</div>
								{/* //
								//
								// */}
								<div className='section'>
									<div className='row'>
										<div className='col-lg-3 col-sm-6'></div>
										{!isLoading && loadedCarouselPhotos && (
											<Carousel>
												{loadedCarouselPhotos.map((photo, key) => (
													<Carousel.Item interval={7000}>
														<img
															className='d-block carouselPhoto'
															src={`${process.env.REACT_APP_AWS_BUCKET}/${photo.photo}`}
															alt='Image One'
														/>
													</Carousel.Item>
												))}
											</Carousel>
										)}
										<div className='col-lg-3 col-sm-6'></div>
									</div>
								</div>
							</div>
						</div>
						{/* //
						//
						//
						//    END OF CAROUSEL
						//
						//
						//
						// */}

						{/* <!-- end of news and updates --> */}
						{/* <!-- beginning of videos --> */}
						<div className='section pt-5 videos'>
							<div className=''>
								{/* <!-- title --> */}
								<div className='row'>
									<div className='col text-center mb-0'>
										<h1 className='text-sh-dark-blue display-2'>Watch!</h1>
										<p className='lead text-secondary'></p>
									</div>
								</div>
								{/* <!-- end of title --> */}

								<div className='row'>
									<div className='col-lg-3 col-sm-3'></div>
									<div className='col-lg-6 col-sm-6'>
										{/* //
							//
							//    Videos
							//
							//
							// */}
										{!isLoading &&
											loadedVideos &&
											loadedVideos.map((video, key) => {
												return (
													<div>
														<h1 style={{ color: 'red' }}>{video.videoTitle}</h1>

														<p
															className='caption'
															style={{
																textAlign: 'center',
																color: 'black',
															}}
														>
															{video.videoCaption}
														</p>
														{
															<YouTube
																style={{ paddingBottom: '3rem' }}
																videoId={ids[key]}
															/>
														}
													</div>
												)
											})}

										{/* //
									
							//
							//
							//
							//
							// */}
										{/* <h2 className='my-3 text-sh-red'>Penguin (Ages 7-9)</h2>
									<p className='text-muted' style={{ padding: '1rem' }}>
										The Penguin Division’s first Practice Draft will be Monday
										August 22 from 6:00 pm to 6:50 pm, the Second Practice Draft
										will be Monday August 29 from 6:00 pm to 6:50 pm. Please
										attend these practice drafts so we can correctly evaluate
										the players to divide the teams up evenly. The first regular
										season game will be Thursday September 8. 10 games +
										playoffs, $75 per player. Regular season games will be
										played at 6:00 pm and possibly 7:00 pm on Thursdays, and at
										5:00 pm and possibly 6:00 pm on Saturdays. Online
										registration ends Sunday August 21.{' '}
										<strong>
											Game times/dates are always subject to change due to the
											number of teams, and the number of teams in the other
											leagues. There may also be some games played on Wednesday
											nights when the availability is needed. Online
											registration ends Sunday August 21.
										</strong>
									</p> */}
									</div>
									<div className='col-lg-3 col-sm-3'></div>
									{/* <div className='underline mb-5 mt-5'></div> */}
								</div>
							</div>
						</div>
						{/* <div className='section pt-5 archives'>
						<div className=''>
							<div className='row'>
								<div className='col text-center mb-0'>
									<h1 className='text-sh-dark-blue display-2'>
										From the Archives
									</h1>
								</div>
							</div>
							<div className='row'></div>
						</div>
					</div> */}

						{/* //
				//
				//
				// */}
						{/* <div className='section p-5 container-gray'>
						<div className='row my-5'>
							<div className='col-md-4 text-center'>
						<i className='icon fas fa-cogs mb-4'></i>
						<h1 className='mb-3 text-sh-dark-blue'>Information</h1>
						<p className='text-sh-red'>
							Current league and team information, as well as links and
							information for this season's sign-ups.
						</p>
					</div> 
							<div className='col-md-3 text-center'></div>
							<div className='col-md-3 text-center'>
								<i className='icon far fa-bell fa-5x mb-4'></i>
								<h1 className='mb-3 text-sh-dark-blue'>League Rules</h1>
								<p className='text-sh-red'>
									Click below for league policies and rules that we will be
									following, along with some general league information.
								</p>
							</div>
							<div className='col-md-3 text-center'>
								<i className='icon fas fa-gift fa-5x mb-4'></i>
								<h1 className='mb-3 text-sh-dark-blue'>Rentals</h1>
								<p className='text-sh-red'>
									In addition to our dek hockey leagues, we also offer rentals
									for birthday parties, practice time, and more!
								</p>
							</div>
							<div className='col-md-3 text-center'></div>
						</div>
					</div> */}
					</div>
				)}
				<Footer />
			</React.Fragment>
		</div>
	)
}

export default Home
