import React from 'react'
import './Footer.css'

const Footer = () => {
	/* return (
		<div className='section footer-background px-5'>
			<div className=''>
				<div className='row text-light py-4 px-5'>
					<div className='col-lg-3 col-sm-6'>
						<h3 className='pb-3 footer-h5'>ADDRESS</h3>
						<p className='medium footer-p text-sh-red'>
							402 W Washington Ave <br></br> Magnolia, NJ
						</p>
					</div>
					<div className='col-lg-3 col-sm-6'>
						<h3 className='pb-3 footer-h5'>PHONE</h3>
						<p className='medium footer-p text-sh-red'>856.701.2390</p>
					</div>
					<div className='col-lg-3 col-sm-6'>
						<h3 className='pb-3 footer-h5'>EMAIL</h3>
						<p className='medium footer-p text-sh-red'>
							<a href='mailto:ahigareda@onmail.com'>ahigareda@onmail.com</a>
						</p>
					</div>
					<div className='col-lg-3 col-sm-6'>
						<h3 className='pb-3 footer-h5'>INSTAGRAM</h3>
						<p className='medium footer-p text-sh-red'>
							<a
								href='https://www.instagram.com/sjsloths_bhc/'
								target='_blank'
								style={{ color: 'white' }}
							>
								<i className='fab fa-instagram fa-2x'></i>
							</a>
						</p>
					</div>
				</div>
				<div className='row'>
					<p
						className='py-4 m-0 copyright text-center'
						style={{ color: 'white' }}
					>
						A Web Application by{' '}
						<a
							href='https://www.shireystatsandstandings.com/#'
							target='_blank'
							style={{ color: 'white' }}
						>
							Shirey Stats and Standings, LLC
						</a>
					</p>
				</div>
			</div>
		</div>
	) */
	return (
		<div style={{ backgroundColor: '#006D75' }}>
			<footer>
				<div>
					<div className='row'>
						<div className='col text-center'>
							<h1
								className='font-weight-light text-capitalize p-3'
								style={{ color: '#EA7200', fontWeight: '600' }}
							>
								South Jersey Sloths
							</h1>
							<p
								className='footerContact medium footer-p'
								style={{ color: 'white' }}
							>
								402 W Washington Ave., Magnolia, NJ
							</p>
							{/* <p
								className='footerContact medium footer-p'
								style={{ color: 'white' }}
							>
								p: 856.701.2390
							</p> */}
							<p
								className='footerContact medium footer-p'
								style={{ color: 'white' }}
							>
								<a href='mailto:ahigareda@onmail.com' className='linkColor'>
									sjsloths@gmail.com
								</a>
							</p>
							<div className='py-3'>
								<a
									href='https://www.instagram.com/sjsloths_bhc/'
									target='_blank'
									style={{ color: '#EA7200' }}
								>
									<i
										className='fab fa-instagram'
										style={{ textShadow: 'black 2px 2px 2px', fontSize: '3em' }}
									></i>
								</a>
							</div>
							<p
								className='py-4 m-0 copyright'
								style={{ color: 'white', backgroundColor: '#686868' }}
							>
								A Web Application by{' '}
								<a
									href='https://www.shireystatsandstandings.com/#'
									target='_blank'
									style={{ color: 'white' }}
								>
									Shirey Stats and Standings, LLC
								</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default Footer
