import React, { useState, useEffect } from 'react'
import './GameSummary.css'
import { useParams } from 'react-router-dom'
import Footer from '../UIElements/Footer'
import sloths from '../../../shared/components/images/Sloths_Logo_Circle.png'

const GameSummary = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [loadedGame, setLoadedGame] = useState()
	const [loadedGameStats, setLoadedGameStats] = useState()
	const [winner, setWinner] = useState()
	const gameId = useParams().gameId
	const [homeRoster, setHomeRoster] = useState()
	const [rosterPlayerGameStats, setRosterPlayerGameStats] = useState()
	const [opponent, setOpponent] = useState()
	const [venue, setVenue] = useState()

	// goes to backend, admin-controller, getGameData
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + `/admin/game/${gameId}`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedGame(responseData.game)
				setLoadedGameStats(responseData.gameStats)
				setWinner(responseData.winner)

				console.log('game: ' + JSON.stringify(responseData.game))
				console.log('game STATS: ' + JSON.stringify(responseData.gameStats))

				setIsLoading(false)
			} catch (err) {
				console.log('error ' + err)
				setError(err)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [gameId])
	//
	//
	//
	//
	//Here, I want to go to the backend, using the gameId, and get the roster list
	//getGameRostersAndPointsPerPeriod in admin-controller.js
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/admin/${gameId}/rostersAndPoints`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setHomeRoster(responseData.homeRoster)
				setOpponent(responseData.opponent)
				setRosterPlayerGameStats(responseData.rosterPlayerGameStats)
				setVenue(responseData.venue)
				setIsLoading(false)
			} catch (err) {
				setError(err)
			}
			setIsLoading(false)
		}
		sendRequest()
	}, [gameId])
	//
	//
	//
	rosterPlayerGameStats &&
		console.log(
			'rosterPlayerGameStats: ' + JSON.stringify(rosterPlayerGameStats)
		)

	homeRoster && console.log('homeRoster: ' + JSON.stringify(homeRoster))
	//
	//
	//
	const getNumber = (playerId) => {
		let playerNumber
		homeRoster.forEach((homePlayer) => {
			if (homePlayer._id === playerId) {
				playerNumber = homePlayer.number
			}
		})
		return playerNumber
	}
	//
	//
	//
	//
	const getPlayerName = (playerId) => {
		let playerFirstName, playerLastName
		homeRoster.forEach((homePlayer) => {
			if (homePlayer._id === playerId) {
				playerFirstName = homePlayer.firstName
				playerLastName = homePlayer.lastName
			}
		})
		return playerFirstName + ' ' + playerLastName
	}
	//
	//
	//
	//
	const getTeamName = (playerId) => {
		let teamName
		homeRoster.forEach((homePlayer) => {
			if (homePlayer._id === playerId) {
				teamName = homePlayer.teamName
			}
		})
		return teamName
	}
	//
	//
	console.log('winner: ' + winner)
	//
	//
	return (
		<React.Fragment>
			{!isLoading && loadedGame && (
				<div className='Summary'>
					{/* <h3 className='h3Center'>Coming soon.......</h3> */}
					<div>
						<img src={sloths} className='sloths' />
					</div>
					<br></br>
					<br></br>
					<h2>
						{loadedGame.teamName} vs. {opponent}
					</h2>
					<h4 className='h3Center'>
						{loadedGame.dayOfWeek},{' '}
						{loadedGame.date.substr(0, 2) === '01' &&
							'Jan. ' + loadedGame.date.substr(3, 2)}
						{loadedGame.date.substr(0, 2) === '02' &&
							'Feb. ' + loadedGame.date.substr(3, 2)}
						{loadedGame.date.substr(0, 2) === '03' &&
							'Mar. ' + loadedGame.date.substr(3, 2)}
						{loadedGame.date.substr(0, 2) === '04' &&
							'Apr. ' + loadedGame.date.substr(3, 2)}
						{loadedGame.date.substr(0, 2) === '05' &&
							'May ' + loadedGame.date.substr(3, 2)}
						{loadedGame.date.substr(0, 2) === '06' &&
							'June ' + loadedGame.date.substr(3, 2)}
						{loadedGame.date.substr(0, 2) === '07' &&
							'July ' + loadedGame.date.substr(3, 2)}
						{loadedGame.date.substr(0, 2) === '08' &&
							'Aug. ' + loadedGame.date.substr(3, 2)}
						{loadedGame.date.substr(0, 2) === '09' &&
							'Sept. ' + loadedGame.date.substr(3, 2)}
						{loadedGame.date.substr(0, 2) === '10' &&
							'Oct. ' + loadedGame.date.substr(3, 2)}
						{loadedGame.date.substr(0, 2) === '11' &&
							'Nov. ' + loadedGame.date.substr(3, 2)}
						{loadedGame.date.substr(0, 2) === '12' &&
							'Dec. ' + loadedGame.date.substr(3, 2)}
						{', '}
						{loadedGame.year}
						{', '}
						{loadedGame.time.substr(0, 2) === '00' &&
							'12:' + loadedGame.time.substr(3, 2) + ' am'}
						{loadedGame.time.substr(0, 2) === '01' &&
							'1:' + loadedGame.time.substr(3, 2) + ' am'}
						{loadedGame.time.substr(0, 2) === '02' &&
							'2:' + loadedGame.time.substr(3, 2) + ' am'}
						{loadedGame.time.substr(0, 2) === '03' &&
							'3:' + loadedGame.time.substr(3, 2) + ' am'}
						{loadedGame.time.substr(0, 2) === '04' &&
							'4:' + loadedGame.time.substr(3, 2) + ' am'}
						{loadedGame.time.substr(0, 2) === '05' &&
							'5:' + loadedGame.time.substr(3, 2) + ' am'}
						{loadedGame.time.substr(0, 2) === '06' &&
							'6:' + loadedGame.time.substr(3, 2) + ' am'}
						{loadedGame.time.substr(0, 2) === '07' &&
							'7:' + loadedGame.time.substr(3, 2) + ' am'}
						{loadedGame.time.substr(0, 2) === '08' &&
							'8:' + loadedGame.time.substr(3, 2) + ' am'}
						{loadedGame.time.substr(0, 2) === '09' &&
							'9:' + loadedGame.time.substr(3, 2) + ' am'}
						{loadedGame.time.substr(0, 2) === '10' &&
							'10:' + loadedGame.time.substr(3, 2) + ' am'}
						{loadedGame.time.substr(0, 2) === '11' &&
							'11:' + loadedGame.time.substr(3, 2) + ' am'}
						{loadedGame.time.substr(0, 2) === '12' &&
							'12:' + loadedGame.time.substr(3, 2) + ' pm'}
						{loadedGame.time.substr(0, 2) === '13' &&
							'1:' + loadedGame.time.substr(3, 2) + ' pm'}
						{loadedGame.time.substr(0, 2) === '14' &&
							'2:' + loadedGame.time.substr(3, 2) + ' pm'}
						{loadedGame.time.substr(0, 2) === '15' &&
							'3:' + loadedGame.time.substr(3, 2) + ' pm'}
						{loadedGame.time.substr(0, 2) === '16' &&
							'4:' + loadedGame.time.substr(3, 2) + ' pm'}
						{loadedGame.time.substr(0, 2) === '17' &&
							'5:' + loadedGame.time.substr(3, 2) + ' pm'}
						{loadedGame.time.substr(0, 2) === '18' &&
							'6:' + loadedGame.time.substr(3, 2) + ' pm'}
						{loadedGame.time.substr(0, 2) === '19' &&
							'7:' + loadedGame.time.substr(3, 2) + ' pm'}
						{loadedGame.time.substr(0, 2) === '20' &&
							'8:' + loadedGame.time.substr(3, 2) + ' pm'}
						{loadedGame.time.substr(0, 2) === '21' &&
							'9:' + loadedGame.time.substr(3, 2) + ' pm'}
						{loadedGame.time.substr(0, 2) === '22' &&
							'10:' + loadedGame.time.substr(3, 2) + ' pm'}
						{loadedGame.time.substr(0, 2) === '23' &&
							'11:' + loadedGame.time.substr(3, 2) + ' pm'}
						{loadedGame.time.substr(0, 2) === '24' &&
							'12:' + loadedGame.time.substr(3, 2) + ' am'}
						{' at '}
						{venue}
					</h4>
					<br></br>
					<br></br>
					{winner && loadedGame.status === 'Overtime' && (
						<h3 className='h3Center'>
							{winner} won in OVERTIME, by a score of {loadedGame.score}
						</h3>
					)}
					{winner && loadedGame.status === 'Shootout' && (
						<h3 className='h3Center'>
							{winner} won in a SHOOTOUT, by a score of {loadedGame.score}
						</h3>
					)}
					{winner &&
						loadedGame.status !== 'Overtime' &&
						loadedGame.status !== 'Shootout' && (
							<h3 className='h3Center'>
								WINNER: {winner}, by a score of {loadedGame.score}
							</h3>
						)}
					{!winner && (
						<h3 className='h3Center'>
							Tie game. Final Score: {loadedGame.score}
						</h3>
					)}
					<br></br>
					<br></br>
					{loadedGameStats.summary && (
						<div className='h3Center'>
							<textarea readOnly className='summaryBox'>
								{loadedGameStats && loadedGameStats.summary}
							</textarea>
						</div>
					)}
					<div>
						{!isLoading && homeRoster && (
							<div className='ScoringSummary'>
								<h1>Scoring Summary</h1>
								<table id='scoringSummary'>
									<thead>
										<tr>
											<th>#</th>
											<th>Player</th>
											{/* <th>Team</th> */}
											<th>Goals</th>
											<th>Assists</th>
										</tr>
									</thead>
									<tbody>
										{rosterPlayerGameStats.map((player, key) => {
											return (
												<tr key={key}>
													<td>{getNumber(player.rosterPlayerId)}</td>
													<td>{getPlayerName(player.rosterPlayerId)}</td>
													{/* <td>{getTeamName(player.rosterPlayerId)}</td> */}
													<td>{player.goals}</td>
													<td>{player.assists}</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						)}
					</div>
				</div>
			)}
			<Footer />
		</React.Fragment>
	)
}

export default GameSummary
